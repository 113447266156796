import React, { useState, useEffect } from 'react';
import { Map } from '../../../components';

import { types as sdkTypes } from '../../../util/sdkLoader';

import { getStoreLocations } from '../../../util/api';

import css from './LandingPageMap.module.css';

const { LatLng } = sdkTypes;

export const LandingPageMap = () => {
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStoreLocations = async () => {
      try {
        const response = await getStoreLocations(JSON.stringify('empty request'));
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching the locations of stores: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStoreLocations();
  }, []);

  if (loading) {
    return (
      <div>
        Loading map locations...
      </div>
    );
  }

  if (!locations || locations.length === 0) {
    return (
      <div>
        No map locations found.
      </div>
    );
  }

  const mapProps = {
    center: new LatLng(locations[0].lat, locations[0].lng),
    address: 'Locago',
    zoom: 22,
    useStaticMap: false,
    locations: locations,
    isLandingPage: true,
  };

  return (
    <div className={css.container}>
      <Map {...mapProps} />
    </div>
  );
};
