import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';




if (typeof window !== 'undefined') {
    import('pure-react-carousel/dist/react-carousel.es.css');
}

import css from './CommunityCarousel.module.css';
import backarrow from './carouselImages/backarrow.svg';
import nextarrow from './carouselImages/nextarrow.svg';

export default class CommunityCarousel extends React.Component {
    render () {
        return (
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={6}
                visibleSlides={3}
                step={1}
                isPlaying={true}
                interval={5000}
                infinite={true}
                className={css.provider}
            >
                <ButtonBack className={css.btn}><img src={backarrow} alt={'back arrow'} style={{width: '4vw', height: '4vw'}}/></ButtonBack>
                <div className={css.sliderContainer}>
                    <Slider className={css.slider}>
                        <Slide index={0} className={css.slideborder}>
                            <div className={css.slide}>
                                <a href="#" className={css.profileLink}>VIEW STORE</a>
                                <img src={require('./carouselImages/person1.webp')} alt={'person 1'} className={css.img}/>
                                <div className={css.label}>
                                    <h3>Small Business</h3>
                                    <p>Owned by ...</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1} className={css.slideborder}>
                            <div className={css.slide}>
                            <   a href="#" className={css.profileLink}>VIEW STORE</a>
                                <img src={require('./carouselImages/person2.webp')} alt={'person 2'} className={css.img}/>
                                <div className={css.label}>
                                    <h3>Small Business</h3>
                                    <p>Owned by ...</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2} className={css.slideborder}>
                            <div className={css.slide}>
                                <a href="#" className={css.profileLink}>VIEW STORE</a>
                                <img src={require('./carouselImages/person3.webp')} alt={'person 3'} className={css.img}/>
                                <div className={css.label}>
                                    <h3>Small Business</h3>
                                    <p>Owned by ...</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={3} className={css.slideborder}>
                            <div className={css.slide}>
                                <a href="#" className={css.profileLink}>VIEW STORE</a>
                                <img src={require('./carouselImages/person4.webp')} alt={'person 4'} className={css.img}/>
                                <div className={css.label}>
                                    <h3>Small Business</h3>
                                    <p>Owned by ...</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={4} className={css.slideborder}>
                            <div className={css.slide}>
                                <a href="#" className={css.profileLink}>VIEW STORE</a>
                                <img src={require('./carouselImages/person5.webp')} alt={'person 5'} className={css.img}/>
                                <div className={css.label}>
                                    <h3>Small Business</h3>
                                    <p>Owned by ...</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={5} className={css.slideborder}>
                            <div className={css.slide}>
                                <a href="#" className={css.profileLink}>VIEW STORE</a>
                                <img src={require('./carouselImages/person6.webp')} alt={'person 6'} className={css.img}/>
                                <div className={css.label}>
                                    <h3>Small Business</h3>
                                    <p>Owned by ...</p>
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                </div>
                <ButtonNext className={css.btn}><img src={nextarrow} alt={'next arrow'} style={{width: '4vw', height: '4vw'}}/></ButtonNext>
            </CarouselProvider>
        );
    }
}
