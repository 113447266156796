import React from 'react';
import css from './Neighborhoods.module.css';

const Neighborhoods = () => {
    return (
        <section className={css.neighborhoods}>
        <div className={css.neighborhoodcaption}>
          <img src={require('../icons/heartmap.webp')} alt={'heart map point'} className={css.heartmapicon}/>
          <h3 className={css.neighborhoodtext}>Discover & Shop Our Most Popular Neighborhoods</h3>
        </div>
        {/* the grid consists of two neighborhood rows */}
        <div className={css.neighborhoodgrid}>
          {/* the row consists of two neighborhoods */}
          <div className={css.neighborhoodrow}>
            <div className={css.neighborhood} >
              <h4 className={css.neighborhoodlabel}>Wicker Park</h4>
              <button className={css.neighborhoodbutton}>Shop 15 stores</button>
              <img src={require('../gridImages/wickerpark.webp')} alt={'Wicker Park'} className={css.neighborhoodimg}/>
            </div>
            <div className={css.neighborhood}>
              <h4 className={css.neighborhoodlabel}>Evanston</h4>
              <button>Shop 15 stores</button>
              <img src={require('../gridImages/evanston.webp')} alt={'Evanston'} className={css.neighborhoodimg}/>
            </div>
          </div>
          <div className={css.neighborhoodrow}>
            <div className={css.neighborhood}>
              <h4 className={css.neighborhoodlabel}>Lincoln Park</h4>
              <button className={css.neighborhoodbutton}>Shop 15 stores</button>
              <img src={require('../gridImages/lincolnpark.webp')} alt={'Lincoln Park'} className={css.neighborhoodimg}/>
            </div>
            <div className={css.neighborhood}>
              <h4 className={css.neighborhoodlabel}>Andersonville</h4>
              <button className={css.neighborhoodbutton}>Shop 15 stores</button>
              <img src={require('../gridImages/andersonville.webp')} alt={'Andersonville'} className={css.neighborhoodimg}/>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Neighborhoods;

