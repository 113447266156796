import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';



if (typeof window !== 'undefined') {
    import('pure-react-carousel/dist/react-carousel.es.css');
}

import css from './TrendingProductsCarousel.module.css';
import backarrow from './carouselImages/backarrow.svg';
import nextarrow from './carouselImages/nextarrow.svg';

export default class TrendingProductsCarousel extends React.Component {
    render () {
        return (
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={5}
                visibleSlides={3}
                step={1}
                isPlaying={true}
                interval={5000}
                infinite={true}
                className={css.provider}
            >
                <ButtonBack className={css.backbtn}><img src={backarrow} alt={'back arrow'} style={{width: '4vw', height: '4vw'}}/></ButtonBack>
                <div className={css.sliderContainer}>
                    <Slider className={css.slider}>
                        <Slide index={0} className={css.slideborder}>
                            <div className={css.slide}>
                                <img src={require('./carouselImages/jacket.webp')} alt={'jacket'} className={css.img}/>
                                <div className={css.label}>
                                    <h1>Chicago Chic</h1>
                                    <p>Striped Flutter Sleeve Overlap Collar</p>
                                    <p>$120.33</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1} className={css.slideborder}>
                            <div className={css.slide}>
                                <img src={require('./carouselImages/dress.webp')} alt={'dress'} className={css.img}/>
                                <div className={css.label}>
                                    <h1>Windy City</h1>
                                    <p>Peplum Hem Blouse</p>
                                    <p>$90.00</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2} className={css.slideborder}>
                            <div className={css.slide}>
                                <img src={require('./carouselImages/sweatshirt.webp')} alt={'sweatshirt'} className={css.img}/>
                                <div className={css.label}>
                                    <h1>Hawthorne</h1>
                                    <p>Oversized Grey Comfort</p>
                                    <p>$140.24</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={3} className={css.slideborder}>
                            <div className={css.slide}>
                                <img src={require('./carouselImages/emrata.webp')} alt={'emrata'} className={css.img}/>
                                <div className={css.label}>
                                    <h1>RATA RATA RATA</h1>
                                    <p className>Em Rata Skirt</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={4} className={css.slideborder}>
                            <div className={css.slide}>
                                <img src={require('./carouselImages/jeans.webp')} alt={'jeans'} className={css.img}/>
                                <div className={css.label}>
                                    <h1>Jeaniverse</h1>
                                    <p>Liam's Jeans</p>
                                    <p>$00.24</p>
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                </div>
                <ButtonNext className={css.nextbtn}><img src={nextarrow} alt={'next arrow'} style={{width: '4vw', height: '4vw'}}/></ButtonNext>
            </CarouselProvider>
        );
    }
}
