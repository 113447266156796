import React from 'react';
import css from './DropDownMenu.module.css'
import CategoryColumn from './CategoryColumn';
const DropDownMenu = ({ column1, column2 }) => {
    return (
        <div className={css.menucontainer}>
            {column1 && <CategoryColumn category={column1} />}
            {column2 && <CategoryColumn category={column2} />}

        </div>);
}

export default DropDownMenu