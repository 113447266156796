import React from 'react';
import {CarouselProvider, Slider, Slide, Dot} from 'pure-react-carousel';
import {useHistory} from "react-router-dom";

if (typeof window !== 'undefined') {
  import('pure-react-carousel/dist/react-carousel.es.css');
}
import css from './HeroCarousel.module.css';

const CustomDotGroup = ({ className, totalSlides }) => (
    <div className={`${css.dotGroup} ${className}`}>
      {Array.from({ length: totalSlides }).map((_, index) => (
        <Dot
          key={index}
          slide={index}
          className={css.dot}
          selectedClassName={css.dotSelected}
        />
      ))}
    </div>
  );

const HeroCarousel = () => {
  const history = useHistory();

  const navigateToSearch = () => {
    history.push('/s');
  };

  return (
    <CarouselProvider
      naturalSlideWidth={80}
      naturalSlideHeight={100}
      totalSlides={3}
      visibleSlides={1}
      step={1}
      isPlaying={true}
      playDirection='forward'
      interval={7000}
      infinite={true}
      className={css.provider}
    >
      <Slider className={css.slider}>
        <Slide index={0} className={css.slide}>
          <img src={require('./carouselImages/womanshopping.webp')} alt={'clothing'}
               className={css.img}/>
          <div className={css.container}>
            <h1 className={css.text}>
              <span>Shop local</span>
              <br/>
              <span>stores near you</span>
            </h1>
            <button onClick={navigateToSearch}>
              Explore your neighborhood
            </button>
          </div>
        </Slide>
        <Slide index={1} className={css.slide}>
          <img src={require('./carouselImages/businessowner.webp')} alt={'clothing'}
               className={css.img}/>
          <div className={css.container}>
            <h1 className={css.text}>Behind every store, there's a friendly face</h1>
            <button>
              Register your local shop
            </button>
          </div>
        </Slide>
        <Slide index={2} className={css.slide}>
          <img src={require('./carouselImages/clothingrack.webp')} alt={'clothing'}
               className={css.img}/>
          <div className={css.container}>
            <h1 className={css.text}>Discover unique fashion finds</h1>
            <button>
              Shop women's apparel
            </button>
          </div>
        </Slide>
      </Slider>
      <CustomDotGroup totalSlides={3}/>
    </CarouselProvider>
  );
}

export default HeroCarousel;
