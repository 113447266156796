import React from 'react';
import css from './CategoryColumn.module.css';

const CategoryColumn = ({ category, level = 1, baseUrl = '' }) => {
    const nextLevel = level + 1;
    const currentUrl = `${baseUrl}pub_categoryLevel${level}=${category?.id}&`;

    return (
        <div className={css.columnContainer}>
            <a href={`/s?${currentUrl}`} className={css.categoryColumn}>
                All {category?.name}
            </a>
            <div className={css.categoryList}>
                {category?.subcategories && category?.subcategories?.map((subCat) => (
                    <div key={subCat?.id} className={css.subCategory}>
                        <a href={`/s?${currentUrl}pub_categoryLevel${nextLevel}=${subCat?.id}`}>
                            {subCat?.name}
                        </a>
                        {subCat?.subcategories && subCat?.subcategories?.length > 0 && (
                            <CategoryColumn category={subCat} level={nextLevel} baseUrl={currentUrl} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoryColumn;

