import React from 'react';
import css from './Footer.module.css';

const FooterSection = ()=> {
    return (
        <div className={css.footer}>
            <div className={css.footercontent}>
                <img src={require('../icons/locagologo.webp')} alt={'Locago logo'} className={css.footerlogo}/>
                <h2 className={css.newsletter}>SIGN UP FOR LOCAGO NEWS.</h2>
                <div className={css.inputgroup}>
                    <input type="email" placeholder="Your email"/>
                    <button className={css.subscribebutton}>SUBSCRIBE</button>
                </div>
                <div className={css.socialicons}>
                    <a href="#"><img src={require('../icons/facebook.webp')} alt={'facebook icon'}/></a>
                    <a href="#"><img src={require('../icons/instagram.webp')} alt={'instagram icon'}/></a>
                    <a href="#"><img src={require('../icons/tiktok.webp')} alt={'tiktok icon'}/></a>
                </div>
            </div>
            <div className={css.links}>
            <a href="#">About Us</a>
            <a href="#">Register Your Business</a>
            <a href="#">Pick Up & Returns</a>
            <a href="#">Terms of Service</a>
            <a href="#">Privacy Policy</a>
            </div>
        </div>
    );
};

export default FooterSection;
